import * as React from "react"
import { graphql } from "gatsby"
import Layout from '../../components/Layout';
import BloggerloveGallery from '../../components/BloggerloveGallery';
import Helmet from 'react-helmet';
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import strings from './strings.json';

const Bloggerlove = (props) => {
  const { storeName } = props.data.site.siteMetadata.gatsbyStorefrontConfig;
  const { location } = props;
  const {title, description} = strings;

  return (
    <Layout>
      <GatsbySeo
        title={title}
        titleTemplate={`%s — ${storeName}`}
        description={description}
        openGraph={{
          url: location.href,
          title: `${title} — ${storeName}`,
          description: description,
          images:[{
            url: location.origin + '/images/logo_800_276.png',
            width: 800,
            height: 276,
          },
          {
            url: location.origin + '/images/logo_800_800.png',
            width: 800,
            height: 800,
          },
          {
            url: location.origin + '/images/logo_382_113.png',
            width: 382,
            height: 113,
          }],
        }}
      />
      <Helmet title={title} titleTemplate={`%s — ${storeName}`} defer={false} />
      <BloggerloveGallery {...props} title={title} description={description} />
    </Layout>
  );
}

export default Bloggerlove;

export const query = graphql`
  query {
    bloggerloves: allBloggerlove {
      nodes {
        name
        url
        image {
          childImageSharp {
            gatsbyImageData(
              width: 450
              height: 450
              placeholder: BLURRED
              backgroundColor: "white"
            )
          }
        }
      }
    }
    site {
      siteMetadata {
        gatsbyStorefrontConfig {
          storeName
        }
      }
    }
  }
`
