import React from 'react';
import { Flex, Box, Heading, Text } from 'rebass';
import Breadcrumbs from '../Breadcrumbs';

import IndividualLove from './IndividualLove';

function BloggerloveGallery(props) {
  const { title, description } = props;
  const bloggerloves = props.data.bloggerloves.nodes.map((node) => {
    return node;
  });

  return (
    <Flex flexWrap="wrap" px={2} pt={3} mx="auto">
      <Flex
        width={1}
        px={4}
        py={2}
        flexWrap='wrap'
        justifyContent="space-between"
        alignItems="flex-end"
      >
        <Box
          sx={{
            display: 'block',
          }}
          pt={1}
        >
          <Breadcrumbs
            productTitle={title}
            separator="/"
          />
        </Box>
        <Box width={1} mb={2}>
          <Heading as="h1" fontSize={[30, 36, 42]} my={3}>
            {title}
          </Heading>
          <Text fontSize={[2, 3]}>{description}</Text>
        </Box>
      </Flex>
      <Flex flexWrap="wrap">
        {bloggerloves.map((bloggerlove) => {
          return (
            <IndividualLove
              key={bloggerlove.id}
              bloggerlove={bloggerlove}
            />
          );
        })}
      </Flex>
    </Flex>
  );
}

export default BloggerloveGallery;
