import React from 'react';
import { Box, Card, Heading, Text } from 'rebass';
import GatsbyLink from 'gatsby-link';
import styled from '@emotion/styled/macro';
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import NoImage from '../../components/Icons/NoImage';

const IndividualLove = ({ bloggerlove }) => {
  const {
    name,
    url,
  } = bloggerlove;
  const bloggerimage = getImage(bloggerlove.image)

  // Empty styled components used for targeting as selectors
  // https://emotion.sh/docs/styled#targeting-another-emotion-component
  const Image = styled(GatsbyImage)``;
  const Title = styled(Heading)``;

  const ByDesignerStyled = styled(Text)`
    position: absolute;
    cursor: pointer;
    z-index: 1;
    opacity: 0;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    transition-property: all;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s;
  `;

  const BloggerloveCardGroupBox = styled(Box)`
    // Transform image:
    & ${Image} {
      transition-property: all;
      transition-duration: 0.6s;
      transition-timing-function: ease-in-out;
      transition-delay: 0s;
    }
    &:hover ${Image} {
      transform: scale(1.05);
      transition-property: all;
      transition-duration: 0.6s;
      transition-timing-function: ease-in-out;
      transition-delay: 0s;
    }
  `;

  return (
    <Card px={4} py={2} mb={4} width={[1, 1 / 2, 1 / 3]}>
      <BloggerloveCardGroupBox>
        <Box>
          <Box sx={{ overflow: 'hidden', mb: 2 }}>
            <a
              href={url}
              style={{ textDecoration: 'none' }}
              target="_blank"
            >
              {bloggerimage ? (
                <Image
                  alt={name}
                  image={bloggerimage}
                />
              ) : (
                <NoImage width="100%" height="100%" color="grey" p={4} />
              )}
            </a>
          </Box>
          <Box sx={{ position: 'relative' }} mb={1}>
            <a
              href={url}
              style={{ textDecoration: 'none' }}
              target="_blank"
            >
              <Title
                as="h3"
                sx={{
                  fontSize: [1, 2, 3],
                  fontFamily: 'body',
                  fontWeight: 'light',
                  color: 'productCatalogTitle',
                  py: 0,
                }}
              >
                {name}
              </Title>
            </a>
          </Box>
        </Box>
      </BloggerloveCardGroupBox>
    </Card>
  );
};

export default IndividualLove;
